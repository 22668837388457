/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    br: "br",
    em: "em",
    code: "code",
    a: "a",
    h2: "h2",
    pre: "pre",
    h3: "h3",
    blockquote: "blockquote"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(Aside, null, React.createElement(_components.p, null, "Tuples group values with a variety of types.", React.createElement(_components.br), "\n", "Arrays group values of a single type.", React.createElement(_components.br), "\n", "They both have a fixed size."), React.createElement(_components.p, null, "While arrays are less flexible in content, they are more flexible in usage.")), "\n", React.createElement(_components.p, null, "The array and the tuple are both ", React.createElement(_components.em, null, "compound"), " types, that means they can hold multiple values.\nAn array and a tuple are both groups of other values."), "\n", React.createElement(_components.p, null, "Tuples are enclosed by parentheses ", React.createElement(_components.code, null, "()"), ".\nEvery element in it is separated by a comma ", React.createElement(_components.code, null, ","), "."), "\n", React.createElement(_components.p, null, "Arrays are enclosed by square braces ", React.createElement(_components.code, null, "[]"), ".\nEvery element in it is separated by a comma ", React.createElement(_components.code, null, ","), "."), "\n", React.createElement(_components.p, null, "They are both fixed in size, they can’t shrink or grow.\nA ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/vec/struct.Vec.html"
  }, "vector"), " is a similar collection type that is allowed to change in size."), "\n", React.createElement(_components.p, null, "So far, they sound ", React.createElement(_components.em, null, "very"), " similar, with just some arbitrary syntax differentiating them."), "\n", React.createElement(_components.h2, {
    id: "types"
  }, "Types"), "\n", React.createElement(_components.p, null, "For an array, every item in that group has to have the same type.\nIf a value in that array is of type ", React.createElement(_components.code, null, "f64"), ", every item in that array ", React.createElement(_components.em, null, "must"), " be of type ", React.createElement(_components.code, null, "f64"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let arr = [1.0, 2.5, 3.14];\n")), "\n", React.createElement(_components.p, null, "For tuples, items in that group can have different types.\nAll values in a tuple ", React.createElement(_components.em, null, "can"), " be the same type, but they can be different too."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let tup = (1, 2.5, \"yummy pie\");\n")), "\n", React.createElement(_components.h3, {
    id: "annotating-the-types"
  }, "Annotating the types"), "\n", React.createElement(_components.p, null, "The Rust compiler is smart and will try to figure out which types it’s dealing with, that means explicit type declarations are not always needed."), "\n", React.createElement(_components.p, null, "For arrays, the type declaration consists of enclosing square brackets. Inside is the type of the elements followed by a semicolon ", React.createElement(_components.code, null, ";"), ", and the length or the array."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let arr: [f64;3] = [1.0, 2.5, 3.14];\n")), "\n", React.createElement(_components.p, null, "For tuples, the type declaration consists of enclosing parentheses. Inside are the types of each element, each one separated by a comma ", React.createElement(_components.code, null, ","), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let tup: (i32, f64, &str) = (1, 2.5, \"yummy pie\");\n")), "\n", React.createElement(_components.h2, {
    id: "accessing-information"
  }, "Accessing information"), "\n", React.createElement(_components.h3, {
    id: "element-by-index"
  }, "Element by index"), "\n", React.createElement(_components.p, null, "Indexes for both tuples and arrays start at 0.\nTuples, somewhat surprisingly, use the ", React.createElement(_components.code, null, "."), " to access an element at a specific index."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let first_arr = arr[0]; // 1.0\nlet first_tup = tup.0; // 1\n")), "\n", React.createElement(_components.h3, {
    id: "element-by-destructuring"
  }, "Element by destructuring"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let (tup_first, tup_second, tup_third) = tup;\nlet [arr_first, arr_second, arr_third] = arr;\nprintln!(\"tup_first: {}, tup_second: {}, tup_third: {}\", tup_first, tup_second, tup_third);\n// tup_first: 1, tup_second: 2.5, tup_third: \"yummy pie\"\nprintln!(\"arr_first: {}, arr_second: {}, arr_third: {}\", arr_first, arr_second, arr_third);\n// arr_first: 1.0, arr_second: 2.5, arr_third: 3.14\n")), "\n", React.createElement(_components.p, null, "It’s a lot more common to destructure tuples.\nIt’s especially handy if a function returns 2 values in a tuple and you want to name each value individually."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn num_and_double(num: i32) -> (i32, i32) {\n    (num, num * 2)\n}\nlet (lucky_num, double_lucky_num) = num_and_double(3);\n")), "\n", React.createElement(_components.h3, {
    id: "length"
  }, "Length"), "\n", React.createElement(_components.p, null, "An array has some metadata associated with it, like its length.\nA tuple does not.\nTuples are essentialy structs with anonymous field names."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let arr_len = arr.len(); // 3\n// let tup_len = tup.len(); // error\n")), "\n", React.createElement(_components.h2, {
    id: "looping"
  }, "Looping"), "\n", React.createElement(_components.p, null, "Iterating over a tuple doesn’t work.\nFor a tuple, each iteration could result in a different type, that doesn’t work.\nSince arrays can only hold a single type, they don’t have that limitation."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "// for num in &tup {\n//     println!(\"num: {}\", num);\n// }\n// error\n\nfor num in &arr {\n    println!(\"num: {}\", num);\n}\n// num: 1.0\n// num: 2.5\n// num: 3.14\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Ok, fine. I’ll iterate over a number range and access each item in the tuple via the dot synt"), "…NOPE", React.createElement(_components.br), "\n", "Tuples can’t be dynamically indexed."), "\n", React.createElement(_components.p, null, "The compiler needs to be able to figure out what type every value is, indexing into it with a variable makes this impossible.\nIf the compiler doesn’t know which index is being used, it can not figure out what type the value at that index is."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Here’s an (slightly contrived) example of why you can’t do stuff like that in Rust:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let myTuple = (1, 2, \"false\");\nlet x = randomNumberGenerator();\nlet y = myTuple.x;\n")), "\n", React.createElement(_components.p, null, "In this case, the compiler wouldn’t be able to figure out at compile-time what type y should be!\nWhereas with an array, you know (and the compiler knows) that indexing is always going to return the same type."), "\n", React.createElement("footer", null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://twitter.com/17cupsofcoffee"
  }, "17cupsofcoffee"))), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let index = 2;\n\nlet arr_pi = arr[2]; // 3.14\nlet also_arr_pi = arr[index]; // 3.14\n\nlet tup_pie = tup.2; // \"yummy pie\"\n// let also_tup_pie = tup.index; // error\n")), "\n", React.createElement(_components.h2, {
    id: "code-summary"
  }, "Code summary"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn main() {\n    let tup = (1,2,3);\n    let arr = [1,2,3];\n    let index = 2;\n\n    println!(\"Array: {:?}\", arr[2]); // Array: 3\n    println!(\"Array: {:?}\", arr[index]); // Array: 3\n    println!(\"Tuple: {:?}\", tup.2); // Tuple: 3\n    // println!(\"Tuple: {:?}\", tup.index); // error\n\n    for num in &arr {\n        println!(\"num: {}\", num);\n    }\n    // num: 1\n    // num: 2\n    // num: 3\n\n    // for num in &tup {\n    //     println!(\"num: {}\", num);\n    // }\n    // error\n\n    let (tup_first, tup_second, tup_third) = tup;\n    let [arr_first, arr_second, arr_third] = arr;\n\n    println!(\"tup_first: {}, tup_second: {}, tup_third: {}\", tup_first, tup_second, tup_third);\n    // tup_first: 1, tup_second: 2, tup_third: 3\n\n    println!(\"arr_first: {}, arr_second: {}, arr_third: {}\", arr_first, arr_second, arr_third);\n    // arr_first: 1, arr_second: 2, arr_third: 3\n\n    // tup.len() // error\n    arr.len() // 3\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
